import React from 'react';
import { NextPage } from 'next'
import { withApollo } from 'helper/apollo';
import { SEO } from 'components/seo';
import { useRouter, Router } from 'next/router';
import { getMobileOperatingSystem, androidAppStoreURL, iosAppStoreURL } from 'helper/mobileapp';
import Log from 'helper/sentry';

type Props = {
  deviceType: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };
};

const DownloadApp: NextPage<Props> = ({ deviceType }) => {
    const router = useRouter();
    const { mobile, tablet, desktop } = deviceType;

    React.useEffect(() => {

        if (mobile || tablet) {
            const os = getMobileOperatingSystem();
            switch (os) {
                case 'ios':
                    Log.debug('App Store: Pushing to iOS', 'app', null);
                    redirect(iosAppStoreURL);
                    break;
                case 'android':
                    Log.debug('App Store: Pushing to Google Play', 'app', null);
                    redirect(androidAppStoreURL);
                    break;
                default:
                    Log.debug(`App Store: Mobile ${os}, pushing to home`, 'app', null);
                    redirect(null);
                    break;
            }
            return;
        }

        Log.debug('App Store: Pushing to home', 'app', null);
        redirect(null);
    }, []);

    const redirect = (url: string) => {
        router.replace(url || '/');
        if (url) setTimeout(() => redirect(null), 25);
    };

    return (
        <SEO
            title='Download on the App Store | Devo'
            description='Order affordable groceries online with Devo to get your favourite brands delivered from your local shops straight to your door. With Devo’s same day delivery you can get food, drinks, alcohol and tobacco delivered in a little as 10 minutes.'
            canonical='/app'
        />
    );
};

export default withApollo(DownloadApp);
